import React, { useState, useRef, useEffect } from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function TabsAllBasicFullWidthPreview(props) {
  const [tab1Selected, setTab1Selected] = useState({
    currentTab: 1,
    noTabs: 3,
  })
  const [tab2Selected, setTab2Selected] = useState({
    currentTab: 1,
    noTabs: 3,
  })
  const [tab3Selected, setTab3Selected] = useState({
    currentTab: 1,
    noTabs: 3,
  })

  const wrapperRef1 = useRef(null)
  const wrapperRef2 = useRef(null)
  const wrapperRef3 = useRef(null)

  const { TabsLgBasicFullWidth, TabsBaseBasicFullWidth, TabsSmBasicFullWidth } =
    props

  const handleKeyDown = e => {
    if (e.keyCode === 39) {
      if (wrapperRef1.current && wrapperRef1.current.contains(e.target)) {
        if (
          tab1Selected.currentTab >= 1 &&
          tab1Selected.currentTab < tab1Selected.noTabs
        ) {
          setTab1Selected({
            ...tab1Selected,
            currentTab: tab1Selected.currentTab + 1,
          })
        } else {
          setTab1Selected({
            ...tab1Selected,
            currentTab: 1,
          })
        }
      }
      if (wrapperRef2.current && wrapperRef2.current.contains(e.target)) {
        if (
          tab2Selected.currentTab >= 1 &&
          tab2Selected.currentTab < tab2Selected.noTabs
        ) {
          setTab2Selected({
            ...tab2Selected,
            currentTab: tab2Selected.currentTab + 1,
          })
        } else {
          setTab2Selected({
            ...tab2Selected,
            currentTab: 1,
          })
        }
      }
      if (wrapperRef3.current && wrapperRef3.current.contains(e.target)) {
        if (
          tab3Selected.currentTab >= 1 &&
          tab3Selected.currentTab < tab3Selected.noTabs
        ) {
          setTab3Selected({
            ...tab3Selected,
            currentTab: tab3Selected.currentTab + 1,
          })
        } else {
          setTab3Selected({
            ...tab3Selected,
            currentTab: 1,
          })
        }
      }
    }

    if (e.keyCode === 37) {
      if (wrapperRef1.current && wrapperRef1.current.contains(e.target)) {
        if (
          tab1Selected.currentTab > 1 &&
          tab1Selected.currentTab <= tab1Selected.noTabs
        ) {
          setTab1Selected({
            ...tab1Selected,
            currentTab: tab1Selected.currentTab - 1,
          })
        } else {
          setTab1Selected({
            ...tab1Selected,
            currentTab: tab1Selected.noTabs,
          })
        }
      }
      if (wrapperRef2.current && wrapperRef2.current.contains(e.target)) {
        if (
          tab2Selected.currentTab > 1 &&
          tab2Selected.currentTab <= tab2Selected.noTabs
        ) {
          setTab2Selected({
            ...tab2Selected,
            currentTab: tab2Selected.currentTab - 1,
          })
        } else {
          setTab2Selected({
            ...tab2Selected,
            currentTab: tab2Selected.noTabs,
          })
        }
      }
      if (wrapperRef3.current && wrapperRef3.current.contains(e.target)) {
        if (
          tab3Selected.currentTab > 1 &&
          tab3Selected.currentTab <= tab3Selected.noTabs
        ) {
          setTab3Selected({
            ...tab3Selected,
            currentTab: tab3Selected.currentTab - 1,
          })
        } else {
          setTab3Selected({
            ...tab3Selected,
            currentTab: tab3Selected.noTabs,
          })
        }
      }
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown)
    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  })

  return (
    <div className="flex flex-col items-center gap-10">
      {/*<!-- Component: Basic lg sized full width tab --> */}
      <CopyComponent
        copyToClipboardCode={TabsLgBasicFullWidth}
        componentName="TabsLgBasicFullWidth"
      >
        <section className="max-w-full" aria-multiselectable="false">
          <ul
            className="flex items-center overflow-x-auto overflow-y-hidden border-b border-slate-200"
            role="tablist"
            ref={wrapperRef1}
          >
            <li className="flex-1" role="presentation ">
              <button
                className={`-mb-px inline-flex h-12 w-full items-center justify-center gap-2 whitespace-nowrap rounded-t border-b-2 px-6 text-sm font-medium tracking-wide transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-600 focus:bg-emerald-50 focus-visible:outline-none disabled:cursor-not-allowed ${
                  tab1Selected.currentTab === 1
                    ? `border-emerald-500 stroke-emerald-500 text-emerald-500 hover:border-emerald-600  hover:text-emerald-600 focus:border-emerald-700 focus:stroke-emerald-700 focus:text-emerald-700 disabled:border-slate-500`
                    : `justify-self-center border-transparent stroke-slate-700 text-slate-700 hover:border-emerald-500 hover:text-emerald-500 focus:border-emerald-600 focus:stroke-emerald-600 focus:text-emerald-600 disabled:text-slate-500`
                }`}
                id="tab-label-1af"
                role="tab"
                aria-setsize="3"
                aria-posinset="1"
                tabindex={`${tab1Selected.currentTab === 1 ? "0" : "-1"}`}
                aria-controls="tab-panel-1af"
                aria-selected={`${
                  tab1Selected.currentTab === 1 ? "true" : "false"
                }`}
                onClick={() =>
                  setTab1Selected({ ...tab1Selected, currentTab: 1 })
                }
              >
                <span>Tab 1</span>
              </button>
            </li>
            <li className="flex-1" role="presentation ">
              <button
                className={`-mb-px inline-flex h-12 w-full items-center justify-center gap-2 whitespace-nowrap rounded-t border-b-2 px-6 text-sm font-medium tracking-wide transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-600 focus:bg-emerald-50 focus-visible:outline-none disabled:cursor-not-allowed ${
                  tab1Selected.currentTab === 2
                    ? `border-emerald-500 stroke-emerald-500 text-emerald-500 hover:border-emerald-600  hover:text-emerald-600 focus:border-emerald-700 focus:stroke-emerald-700 focus:text-emerald-700 disabled:border-slate-500`
                    : `justify-self-center border-transparent stroke-slate-700 text-slate-700 hover:border-emerald-500 hover:text-emerald-500 focus:border-emerald-600 focus:stroke-emerald-600 focus:text-emerald-600 disabled:text-slate-500`
                }`}
                id="tab-label-2af"
                role="tab"
                aria-setsize="3"
                aria-posinset="2"
                tabindex={`${tab1Selected.currentTab === 2 ? "0" : "-1"}`}
                aria-controls="tab-panel-2af"
                aria-selected={`${
                  tab1Selected.currentTab === 2 ? "true" : "false"
                }`}
                onClick={() =>
                  setTab1Selected({ ...tab1Selected, currentTab: 2 })
                }
              >
                <span>Tab 2</span>
              </button>
            </li>
            <li className="flex-1" role="presentation ">
              <button
                className={`-mb-px inline-flex h-12 w-full items-center justify-center gap-2 whitespace-nowrap rounded-t border-b-2 px-6 text-sm font-medium tracking-wide transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-600 focus:bg-emerald-50 focus-visible:outline-none disabled:cursor-not-allowed ${
                  tab1Selected.currentTab === 3
                    ? `border-emerald-500 stroke-emerald-500 text-emerald-500 hover:border-emerald-600  hover:text-emerald-600 focus:border-emerald-700 focus:stroke-emerald-700 focus:text-emerald-700 disabled:border-slate-500`
                    : `justify-self-center border-transparent stroke-slate-700 text-slate-700 hover:border-emerald-500 hover:text-emerald-500 focus:border-emerald-600 focus:stroke-emerald-600 focus:text-emerald-600 disabled:text-slate-500`
                }`}
                id="tab-label-3af"
                role="tab"
                aria-setsize="3"
                aria-posinset="3"
                tabindex={`${tab1Selected.currentTab === 3 ? "0" : "-1"}`}
                aria-controls="tab-panel-3af"
                aria-selected={`${
                  tab1Selected.currentTab === 3 ? "true" : "false"
                }`}
                onClick={() =>
                  setTab1Selected({ ...tab1Selected, currentTab: 3 })
                }
              >
                <span>Tab 3</span>
              </button>
            </li>
          </ul>
          <div className="">
            <div
              className={`px-6 py-4 ${
                tab1Selected.currentTab === 1 ? "" : "hidden"
              }`}
              id="tab-panel-1af"
              aria-hidden={`${
                tab1Selected.currentTab === 1 ? "true" : "false"
              }`}
              role="tabpanel"
              aria-labelledby="tab-label-1af"
              tabindex="-1"
            >
              <p>
                What is the recipe for successful achievement? To my mind there
                are just four essential ingredients: Choose a career you love,
                give it the best there is in you, seize your opportunities, and
                be a member of the team.
              </p>
            </div>
            <div
              className={`px-6 py-4 ${
                tab1Selected.currentTab === 2 ? "" : "hidden"
              }`}
              id="tab-panel-2af"
              aria-hidden={`${
                tab1Selected.currentTab === 2 ? "true" : "false"
              }`}
              role="tabpanel"
              aria-labelledby="tab-label-2af"
              tabindex="-1"
            >
              <p>
                One must be entirely sensitive to the structure of the material
                that one is handling. One must yield to it in tiny details of
                execution, perhaps the handling of the surface or grain, and one
                must master it as a whole.
              </p>
            </div>
            <div
              className={`px-6 py-4 ${
                tab1Selected.currentTab === 3 ? "" : "hidden"
              }`}
              id="tab-panel-3af"
              aria-hidden={`${
                tab1Selected.currentTab === 3 ? "true" : "false"
              }`}
              role="tabpanel"
              aria-labelledby="tab-label-3af"
              tabindex="-1"
            >
              <p>
                Even though there is no certainty that the expected results of
                our work will manifest, we have to remain committed to our work
                and duties; because, even if the results are slated to arrive,
                they cannot do so without the performance of work.
              </p>
            </div>
          </div>
        </section>
      </CopyComponent>
      {/*<!-- End Basic lg sized full width tab --> */}

      {/*<!-- Component: Basic base sized full width tab --> */}
      <CopyComponent
        copyToClipboardCode={TabsBaseBasicFullWidth}
        componentName="TabsBaseBasicFullWidth"
      >
        <section className="max-w-full" aria-multiselectable="false">
          <ul
            className="flex items-center overflow-x-auto overflow-y-hidden border-b border-slate-200"
            role="tablist"
            ref={wrapperRef2}
          >
            <li className="flex-1" role="presentation ">
              <button
                className={`-mb-px inline-flex h-10 w-full items-center justify-center gap-2 whitespace-nowrap rounded-t border-b-2 px-5 text-sm font-medium tracking-wide transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-600 focus:bg-emerald-50 focus-visible:outline-none disabled:cursor-not-allowed ${
                  tab2Selected.currentTab === 1
                    ? `border-emerald-500 stroke-emerald-500 text-emerald-500 hover:border-emerald-600  hover:text-emerald-600 focus:border-emerald-700 focus:stroke-emerald-700 focus:text-emerald-700 disabled:border-slate-500`
                    : `justify-self-center border-transparent stroke-slate-700 text-slate-700 hover:border-emerald-500 hover:text-emerald-500 focus:border-emerald-600 focus:stroke-emerald-600 focus:text-emerald-600 disabled:text-slate-500`
                }`}
                id="tab-label-1bf"
                role="tab"
                aria-setsize="3"
                aria-posinset="1"
                tabindex={`${tab2Selected.currentTab === 1 ? "0" : "-1"}`}
                aria-controls="tab-panel-1bf"
                aria-selected={`${
                  tab2Selected.currentTab === 1 ? "true" : "false"
                }`}
                onClick={() =>
                  setTab2Selected({ ...tab2Selected, currentTab: 1 })
                }
              >
                <span>Tab 1</span>
              </button>
            </li>
            <li className="flex-1" role="presentation ">
              <button
                className={`-mb-px inline-flex h-10 w-full items-center justify-center gap-2 whitespace-nowrap rounded-t border-b-2 px-5 text-sm font-medium tracking-wide transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-600 focus:bg-emerald-50 focus-visible:outline-none disabled:cursor-not-allowed ${
                  tab2Selected.currentTab === 2
                    ? `border-emerald-500 stroke-emerald-500 text-emerald-500 hover:border-emerald-600  hover:text-emerald-600 focus:border-emerald-700 focus:stroke-emerald-700 focus:text-emerald-700 disabled:border-slate-500`
                    : `justify-self-center border-transparent stroke-slate-700 text-slate-700 hover:border-emerald-500 hover:text-emerald-500 focus:border-emerald-600 focus:stroke-emerald-600 focus:text-emerald-600 disabled:text-slate-500`
                }`}
                id="tab-label-2bf"
                role="tab"
                aria-setsize="3"
                aria-posinset="2"
                tabindex={`${tab2Selected.currentTab === 2 ? "0" : "-1"}`}
                aria-controls="tab-panel-2bf"
                aria-selected={`${
                  tab2Selected.currentTab === 2 ? "true" : "false"
                }`}
                onClick={() =>
                  setTab2Selected({ ...tab2Selected, currentTab: 2 })
                }
              >
                <span>Tab 2</span>
              </button>
            </li>
            <li className="flex-1" role="presentation ">
              <button
                className={`-mb-px inline-flex h-10 w-full items-center justify-center gap-2 whitespace-nowrap rounded-t border-b-2 px-5 text-sm font-medium tracking-wide transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-600 focus:bg-emerald-50 focus-visible:outline-none disabled:cursor-not-allowed ${
                  tab2Selected.currentTab === 3
                    ? `border-emerald-500 stroke-emerald-500 text-emerald-500 hover:border-emerald-600  hover:text-emerald-600 focus:border-emerald-700 focus:stroke-emerald-700 focus:text-emerald-700 disabled:border-slate-500`
                    : `justify-self-center border-transparent stroke-slate-700 text-slate-700 hover:border-emerald-500 hover:text-emerald-500 focus:border-emerald-600 focus:stroke-emerald-600 focus:text-emerald-600 disabled:text-slate-500`
                }`}
                id="tab-label-3bf"
                role="tab"
                aria-setsize="3"
                aria-posinset="3"
                tabindex={`${tab2Selected.currentTab === 3 ? "0" : "-1"}`}
                aria-controls="tab-panel-3bf"
                aria-selected={`${
                  tab2Selected.currentTab === 3 ? "true" : "false"
                }`}
                onClick={() =>
                  setTab2Selected({ ...tab2Selected, currentTab: 3 })
                }
              >
                <span>Tab 3</span>
              </button>
            </li>
          </ul>
          <div className="">
            <div
              className={`px-5 py-4 ${
                tab2Selected.currentTab === 1 ? "" : "hidden"
              }`}
              id="tab-panel-1bf"
              aria-hidden={`${
                tab2Selected.currentTab === 1 ? "true" : "false"
              }`}
              role="tabpanel"
              aria-labelledby="tab-label-1bf"
              tabindex="-1"
            >
              <p>
                What is the recipe for successful achievement? To my mind there
                are just four essential ingredients: Choose a career you love,
                give it the best there is in you, seize your opportunities, and
                be a member of the team.
              </p>
            </div>
            <div
              className={`px-5 py-4 ${
                tab2Selected.currentTab === 2 ? "" : "hidden"
              }`}
              id="tab-panel-2bf"
              aria-hidden={`${
                tab2Selected.currentTab === 2 ? "true" : "false"
              }`}
              role="tabpanel"
              aria-labelledby="tab-label-2bf"
              tabindex="-1"
            >
              <p>
                One must be entirely sensitive to the structure of the material
                that one is handling. One must yield to it in tiny details of
                execution, perhaps the handling of the surface or grain, and one
                must master it as a whole.
              </p>
            </div>
            <div
              className={`px-5 py-4 ${
                tab2Selected.currentTab === 3 ? "" : "hidden"
              }`}
              id="tab-panel-3bf"
              aria-hidden={`${
                tab2Selected.currentTab === 3 ? "true" : "false"
              }`}
              role="tabpanel"
              aria-labelledby="tab-label-3bf"
              tabindex="-1"
            >
              <p>
                Even though there is no certainty that the expected results of
                our work will manifest, we have to remain committed to our work
                and duties; because, even if the results are slated to arrive,
                they cannot do so without the performance of work.
              </p>
            </div>
          </div>
        </section>
      </CopyComponent>
      {/*<!-- End Basic base sized full width tab --> */}

      {/*<!-- Component: Basic sm sized full width tab --> */}
      <CopyComponent
        copyToClipboardCode={TabsSmBasicFullWidth}
        componentName="TabsSmBasicFullWidth"
      >
        <section className="max-w-full" aria-multiselectable="false">
          <ul
            className="flex items-center overflow-x-auto overflow-y-hidden border-b border-slate-200"
            role="tablist"
            ref={wrapperRef3}
          >
            <li className="flex-1" role="presentation ">
              <button
                className={`-mb-px inline-flex h-8 w-full items-center justify-center gap-2 whitespace-nowrap rounded-t border-b-2 px-4 text-xs font-medium tracking-wide transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-600 focus:bg-emerald-50 focus-visible:outline-none disabled:cursor-not-allowed ${
                  tab3Selected.currentTab === 1
                    ? `border-emerald-500 stroke-emerald-500 text-emerald-500 hover:border-emerald-600  hover:text-emerald-600 focus:border-emerald-700 focus:stroke-emerald-700 focus:text-emerald-700 disabled:border-slate-500`
                    : `justify-self-center border-transparent stroke-slate-700 text-slate-700 hover:border-emerald-500 hover:text-emerald-500 focus:border-emerald-600 focus:stroke-emerald-600 focus:text-emerald-600 disabled:text-slate-500`
                }`}
                id="tab-label-1cf"
                role="tab"
                aria-setsize="3"
                aria-posinset="1"
                tabindex={`${tab3Selected.currentTab === 1 ? "0" : "-1"}`}
                aria-controls="tab-panel-1cf"
                aria-selected={`${
                  tab3Selected.currentTab === 1 ? "true" : "false"
                }`}
                onClick={() =>
                  setTab3Selected({ ...tab3Selected, currentTab: 1 })
                }
              >
                <span>Tab 1</span>
              </button>
            </li>
            <li className="flex-1" role="presentation ">
              <button
                className={`-mb-px inline-flex h-8 w-full items-center justify-center gap-2 whitespace-nowrap rounded-t border-b-2 px-4 text-xs font-medium tracking-wide transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-600 focus:bg-emerald-50 focus-visible:outline-none disabled:cursor-not-allowed ${
                  tab3Selected.currentTab === 2
                    ? `border-emerald-500 stroke-emerald-500 text-emerald-500 hover:border-emerald-600  hover:text-emerald-600 focus:border-emerald-700 focus:stroke-emerald-700 focus:text-emerald-700 disabled:border-slate-500`
                    : `justify-self-center border-transparent stroke-slate-700 text-slate-700 hover:border-emerald-500 hover:text-emerald-500 focus:border-emerald-600 focus:stroke-emerald-600 focus:text-emerald-600 disabled:text-slate-500`
                }`}
                id="tab-label-2cf"
                role="tab"
                aria-setsize="3"
                aria-posinset="2"
                tabindex={`${tab3Selected.currentTab === 2 ? "0" : "-1"}`}
                aria-controls="tab-panel-2cf"
                aria-selected={`${
                  tab3Selected.currentTab === 2 ? "true" : "false"
                }`}
                onClick={() =>
                  setTab3Selected({ ...tab3Selected, currentTab: 2 })
                }
              >
                <span>Tab 2</span>
              </button>
            </li>
            <li className="flex-1" role="presentation ">
              <button
                className={`-mb-px inline-flex h-8 w-full items-center justify-center gap-2 whitespace-nowrap rounded-t border-b-2 px-4 text-xs font-medium tracking-wide transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-600 focus:bg-emerald-50 focus-visible:outline-none disabled:cursor-not-allowed ${
                  tab3Selected.currentTab === 3
                    ? `border-emerald-500 stroke-emerald-500 text-emerald-500 hover:border-emerald-600  hover:text-emerald-600 focus:border-emerald-700 focus:stroke-emerald-700 focus:text-emerald-700 disabled:border-slate-500`
                    : `justify-self-center border-transparent stroke-slate-700 text-slate-700 hover:border-emerald-500 hover:text-emerald-500 focus:border-emerald-600 focus:stroke-emerald-600 focus:text-emerald-600 disabled:text-slate-500`
                }`}
                id="tab-label-3cf"
                role="tab"
                aria-setsize="3"
                aria-posinset="3"
                tabindex={`${tab3Selected.currentTab === 3 ? "0" : "-1"}`}
                aria-controls="tab-panel-3cf"
                aria-selected={`${
                  tab3Selected.currentTab === 3 ? "true" : "false"
                }`}
                onClick={() =>
                  setTab3Selected({ ...tab3Selected, currentTab: 3 })
                }
              >
                <span>Tab 3</span>
              </button>
            </li>
          </ul>
          <div className="">
            <div
              className={`p-4 text-sm ${
                tab3Selected.currentTab === 1 ? "" : "hidden"
              }`}
              id="tab-panel-1cf"
              aria-hidden={`${
                tab3Selected.currentTab === 1 ? "true" : "false"
              }`}
              role="tabpanel"
              aria-labelledby="tab-label-1cf"
              tabindex="-1"
            >
              <p>
                What is the recipe for successful achievement? To my mind there
                are just four essential ingredients: Choose a career you love,
                give it the best there is in you, seize your opportunities, and
                be a member of the team.
              </p>
            </div>
            <div
              className={`p-4 text-sm ${
                tab3Selected.currentTab === 2 ? "" : "hidden"
              }`}
              id="tab-panel-2cf"
              aria-hidden={`${
                tab3Selected.currentTab === 2 ? "true" : "false"
              }`}
              role="tabpanel"
              aria-labelledby="tab-label-2cf"
              tabindex="-1"
            >
              <p>
                One must be entirely sensitive to the structure of the material
                that one is handling. One must yield to it in tiny details of
                execution, perhaps the handling of the surface or grain, and one
                must master it as a whole.
              </p>
            </div>
            <div
              className={`p-4 text-sm ${
                tab3Selected.currentTab === 3 ? "" : "hidden"
              }`}
              id="tab-panel-3cf"
              aria-hidden={`${
                tab3Selected.currentTab === 3 ? "true" : "false"
              }`}
              role="tabpanel"
              aria-labelledby="tab-label-3cf"
              tabindex="-1"
            >
              <p>
                Even though there is no certainty that the expected results of
                our work will manifest, we have to remain committed to our work
                and duties; because, even if the results are slated to arrive,
                they cannot do so without the performance of work.
              </p>
            </div>
          </div>
        </section>
      </CopyComponent>
      {/*<!-- End Basic sm sized full width tab --> */}
    </div>
  )
}
