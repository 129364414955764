import React, { useState } from "react"

// Components
import Seo from "../../../components/seo"
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import ContentNavigation from "../../../components/scrollspy"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"
import TabsHeroComponent from "../../../library/components/tabs/react/_preview/TabsHeroComponent"
// Preview
import TabsAllBasicPreview from "../../../library/components/tabs/react/_preview/basic"
import TabsAllBasicLeadingIconPreview from "../../../library/components/tabs/react/_preview/basic-leading_icon"
import TabsAllPillPreview from "../../../library/components/tabs/react/_preview/pill"
import TabsAllPillLeadingIconPreview from "../../../library/components/tabs/react/_preview/pill-leading_icon"
import TabsAllBasicFullWidthPreview from "../../../library/components/tabs/react/_preview/basic-full_width"

// Lg
const TabsLgBasicJsx = require("!!raw-loader!../../../library/components/tabs/react/lg/basic.jsx")
const TabsLgBasicHTML = require("!!raw-loader!../../../library/components/tabs/html/lg/basic.html")

const TabsLgBasicLeadingIconJsx = require("!!raw-loader!../../../library/components/tabs/react/lg/basic-leading_icon.jsx")
const TabsLgBasicLeadingIconHTML = require("!!raw-loader!../../../library/components/tabs/html/lg/basic-leading_icon.html")

const TabsLgPillJsx = require("!!raw-loader!../../../library/components/tabs/react/lg/pill.jsx")
const TabsLgPillHTML = require("!!raw-loader!../../../library/components/tabs/html/lg/pill.html")

const TabsLgPillLeadingIconJsx = require("!!raw-loader!../../../library/components/tabs/react/lg/pill-leading_icon.jsx")
const TabsLgPillLeadingIconHTML = require("!!raw-loader!../../../library/components/tabs/html/lg/pill-leading_icon.html")

const TabsLgBasicFullWidthJsx = require("!!raw-loader!../../../library/components/tabs/react/lg/basic-full_width.jsx")
const TabsLgBasicFullWidthHTML = require("!!raw-loader!../../../library/components/tabs/html/lg/basic-full_width.html")

// Base
const TabsBaseBasicJsx = require("!!raw-loader!../../../library/components/tabs/react/base/basic.jsx")
const TabsBaseBasicHTML = require("!!raw-loader!../../../library/components/tabs/html/base/basic.html")

const TabsBaseBasicLeadingIconJsx = require("!!raw-loader!../../../library/components/tabs/react/base/basic-leading_icon.jsx")
const TabsBaseBasicLeadingIconHTML = require("!!raw-loader!../../../library/components/tabs/html/base/basic-leading_icon.html")

const TabsBasePillJsx = require("!!raw-loader!../../../library/components/tabs/react/base/pill.jsx")
const TabsBasePillHTML = require("!!raw-loader!../../../library/components/tabs/html/base/pill.html")

const TabsBasePillLeadingIconJsx = require("!!raw-loader!../../../library/components/tabs/react/base/pill-leading_icon.jsx")
const TabsBasePillLeadingIconHTML = require("!!raw-loader!../../../library/components/tabs/html/base/pill-leading_icon.html")

const TabsBaseBasicFullWidthJsx = require("!!raw-loader!../../../library/components/tabs/react/base/basic-full_width.jsx")
const TabsBaseBasicFullWidthHTML = require("!!raw-loader!../../../library/components/tabs/html/base/basic-full_width.html")

// Sm
const TabsSmBasicJsx = require("!!raw-loader!../../../library/components/tabs/react/sm/basic.jsx")
const TabsSmBasicHTML = require("!!raw-loader!../../../library/components/tabs/html/sm/basic.html")

const TabsSmBasicLeadingIconJsx = require("!!raw-loader!../../../library/components/tabs/react/sm/basic-leading_icon.jsx")
const TabsSmBasicLeadingIconHTML = require("!!raw-loader!../../../library/components/tabs/html/sm/basic-leading_icon.html")

const TabsSmPillJsx = require("!!raw-loader!../../../library/components/tabs/react/sm/pill.jsx")
const TabsSmPillHTML = require("!!raw-loader!../../../library/components/tabs/html/sm/pill.html")

const TabsSmPillLeadingIconJsx = require("!!raw-loader!../../../library/components/tabs/react/sm/pill-leading_icon.jsx")
const TabsSmPillLeadingIconHTML = require("!!raw-loader!../../../library/components/tabs/html/sm/pill-leading_icon.html")

const TabsSmBasicFullWidthJsx = require("!!raw-loader!../../../library/components/tabs/react/sm/basic-full_width.jsx")
const TabsSmBasicFullWidthHTML = require("!!raw-loader!../../../library/components/tabs/html/sm/basic-full_width.html")

export default function TabsPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "BasicTabs",
      title: "Basic Tabs",
      active_tab: 1,
    },
    {
      component_name: "BasicFullWidthTabs",
      title: "Full Width",
      active_tab: 1,
    },
    {
      component_name: "BasicWithLeadingIconTabs",
      title: "With leading icon",
      active_tab: 1,
    },
    {
      component_name: "PillTabs",
      title: "Pill",
      active_tab: 1,
    },
    {
      component_name: "PillTabsWithLeadingIcon",
      title: "Pill with leading icon",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Tabs - WindUI Component Library"
        ogtitle="Tailwind CSS Tabs - WindUI Component Library"
        description="Tab components organize content across different screens, data sets, and other interactions. Built with Tailwind CSS by WindUI."
        ogdescription="Tab components organize content across different screens, data sets, and other interactions. Built with Tailwind CSS by WindUI."
        url="components/tabs/"
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Tabs, Tabs, Tab Components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Tabs</h1>
          <p>
            Tabs organize content across different screens, data sets, and other
            interactions.
          </p>

          {/* Hero Preview Section */}
          <section
            className="not-prose max-w-full"
            aria-multiselectable="false"
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16">
              <TabsHeroComponent />
            </div>
          </section>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          {/* Basic Tabs */}
          <h3 id="BasicTabs">
            Basic Tabs <span className="info-tip">Requires JS</span>
          </h3>

          <PreviewBlock
            id="BasicTabs"
            HtmlComponent={TabsLgBasicHTML.default}
            JsxComponent={TabsLgBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <TabsAllBasicPreview
                TabsLgBasic={
                  activeTabs[0].active_tab === 1
                    ? TabsLgBasicHTML.default
                    : TabsLgBasicJsx.default
                }
                TabsBaseBasic={
                  activeTabs[0].active_tab === 1
                    ? TabsBaseBasicHTML.default
                    : TabsBaseBasicJsx.default
                }
                TabsSmBasic={
                  activeTabs[0].active_tab === 1
                    ? TabsSmBasicHTML.default
                    : TabsSmBasicJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="BasicFullWidthTabs">
            Basic Tabs Full Width <span className="info-tip">Requires JS</span>
          </h3>

          <PreviewBlock
            id="BasicFullWidthTabs"
            HtmlComponent={TabsLgBasicFullWidthHTML.default}
            JsxComponent={TabsLgBasicFullWidthJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <TabsAllBasicFullWidthPreview
                TabsLgBasicFullWidth={
                  activeTabs[1].active_tab === 1
                    ? TabsLgBasicFullWidthHTML.default
                    : TabsLgBasicFullWidthJsx.default
                }
                TabsBaseBasicFullWidth={
                  activeTabs[1].active_tab === 1
                    ? TabsBaseBasicFullWidthHTML.default
                    : TabsBaseBasicFullWidthJsx.default
                }
                TabsSmBasicFullWidth={
                  activeTabs[1].active_tab === 1
                    ? TabsSmBasicFullWidthHTML.default
                    : TabsSmBasicFullWidthJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="BasicWithLeadingIconTabs">
            Basic Tabs With Leading Icon{" "}
            <span className="info-tip">Requires JS</span>
          </h3>

          <PreviewBlock
            id="BasicWithLeadingIconTabs"
            HtmlComponent={TabsLgBasicLeadingIconHTML.default}
            JsxComponent={TabsLgBasicLeadingIconJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <TabsAllBasicLeadingIconPreview
                TabsLgBasicLeadingIcon={
                  activeTabs[2].active_tab === 1
                    ? TabsLgBasicLeadingIconHTML.default
                    : TabsLgBasicLeadingIconJsx.default
                }
                TabsBaseBasicLeadingIcon={
                  activeTabs[2].active_tab === 1
                    ? TabsBaseBasicLeadingIconHTML.default
                    : TabsBaseBasicLeadingIconJsx.default
                }
                TabsSmBasicLeadingIcon={
                  activeTabs[2].active_tab === 1
                    ? TabsSmBasicLeadingIconHTML.default
                    : TabsSmBasicLeadingIconJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="PillTabs">
            Pill Tabs <span className="info-tip">Requires JS</span>
          </h3>

          <PreviewBlock
            id="PillTabs"
            HtmlComponent={TabsLgPillHTML.default}
            JsxComponent={TabsLgPillJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <TabsAllPillPreview
                TabsLgPill={
                  activeTabs[3].active_tab === 1
                    ? TabsLgPillHTML.default
                    : TabsLgPillJsx.default
                }
                TabsBasePill={
                  activeTabs[3].active_tab === 1
                    ? TabsBasePillHTML.default
                    : TabsBasePillJsx.default
                }
                TabsSmPill={
                  activeTabs[3].active_tab === 1
                    ? TabsSmPillHTML.default
                    : TabsSmPillJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="PillTabsWithLeadingIcon">
            Pill Tabs With Leading Icon{" "}
            <span className="info-tip">Requires JS</span>
          </h3>

          <PreviewBlock
            id="PillTabsWithLeadingIcon"
            HtmlComponent={TabsLgPillLeadingIconHTML.default}
            JsxComponent={TabsLgPillLeadingIconJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <TabsAllPillLeadingIconPreview
                TabsLgPillLeadingIcon={
                  activeTabs[4].active_tab === 1
                    ? TabsLgPillLeadingIconHTML.default
                    : TabsLgPillLeadingIconJsx.default
                }
                TabsBasePillLeadingIcon={
                  activeTabs[4].active_tab === 1
                    ? TabsBasePillLeadingIconHTML.default
                    : TabsBasePillLeadingIconJsx.default
                }
                TabsSmPillLeadingIcon={
                  activeTabs[4].active_tab === 1
                    ? TabsSmPillLeadingIconHTML.default
                    : TabsSmPillLeadingIconJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            Use tabs to organize and allow navigation between groups of content
            that are related and at the same level of hierarchy.
          </p>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>Tabs consist of the following main components:</p>
          <ul>
            <li>
              <strong>Section:</strong> a section that wraps both the tabs
              (buttons) and the tab panels that hosts the tab contents.
            </li>
            <li>
              <strong>Tabs:</strong> a list that contains buttons and serves as
              a label for one of the tab panels and can be activated to display
              that panel.
            </li>
            <li>
              <strong>Tab panels:</strong> a set of divs that contains the
              content associated with a tab.
            </li>
          </ul>
          <p>
            Tabs come in 2 styles (base and pill tabs) and they can contain text
            and an icon that is only visible on big screens.
          </p>
          <ul>
            <li>
              <strong>Small:</strong> 2em height (32px), 1rem (16px) horizontal
              padding and 0.75rem (12px) font size. Icons on small tabs are 1rem
              (16px) wide and tall and have a 0.5rem (8px) distance from the
              text.
            </li>
            <li>
              <strong>Base:</strong> 2.5em height (40px), 1.25rem (20px)
              horizontal padding and 0.875rem (14px) font size. Icons on base
              tabs are 1.25rem (20px) wide and tall and have a 0.5rem (8px)
              distance from the text.
            </li>
            <li>
              <strong>Large:</strong> 3em height (48px), 1.5rem (24px)
              horizontal padding and 0.875rem (14px) font size. Icons on large
              tabs are 1.25rem (20px) wide and tall and have a 0.5rem (8px)
              distance from the text.
            </li>
          </ul>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <p>
            Tabs are wrapped in a{" "}
            <code>&lt;section aria-multiselectable=&quot;false&quot;&gt;</code>{" "}
            attribute that indicates that the user may not select more than one
            item from the current selectable descendants.
          </p>
          <p>
            A <code>&lt;ul role=&quot;tablist&quot;&gt;</code> element wraps the
            tab button list.
          </p>
          <p>Each tab button has the following aria labels:</p>
          <ul>
            <li>
              <strong>aria-setsize:</strong> indicates the total available
              number of tabs.
            </li>
            <li>
              <strong>aria-posinset:</strong> indicates the index of each tab
              within the set of tabs.
            </li>
            <li>
              <strong>aria-controls:</strong> points to the id of the tabpanel
              that the tab controls (and makes visible).{" "}
            </li>
            <li>
              <strong>aria-selected:</strong> set to true for the active tab.
            </li>
          </ul>
          <p>Each tab panel has the following aria labels:</p>
          <ul>
            <li>
              <strong>aria-hidden:</strong> set to false for the active tab
              panel and false to all others.
            </li>
            <li>
              <strong>aria-labelledby</strong> points to the id of the tab
              button that controls this panel.
            </li>
          </ul>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
