import React, { useEffect, useState, useRef } from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function TabsAllBasicLeadingIconPreview(props) {
  const [tab1Selected, setTab1Selected] = useState({
    currentTab: 1,
    noTabs: 3,
  })
  const [tab2Selected, setTab2Selected] = useState({
    currentTab: 1,
    noTabs: 3,
  })
  const [tab3Selected, setTab3Selected] = useState({
    currentTab: 1,
    noTabs: 3,
  })

  const wrapperRef1 = useRef(null)
  const wrapperRef2 = useRef(null)
  const wrapperRef3 = useRef(null)

  const {
    TabsLgBasicLeadingIcon,
    TabsBaseBasicLeadingIcon,
    TabsSmBasicLeadingIcon,
  } = props

  const handleKeyDown = e => {
    if (e.keyCode === 39) {
      if (wrapperRef1.current && wrapperRef1.current.contains(e.target)) {
        if (
          tab1Selected.currentTab >= 1 &&
          tab1Selected.currentTab < tab1Selected.noTabs
        ) {
          setTab1Selected({
            ...tab1Selected,
            currentTab: tab1Selected.currentTab + 1,
          })
        } else {
          setTab1Selected({
            ...tab1Selected,
            currentTab: 1,
          })
        }
      }
      if (wrapperRef2.current && wrapperRef2.current.contains(e.target)) {
        if (
          tab2Selected.currentTab >= 1 &&
          tab2Selected.currentTab < tab2Selected.noTabs
        ) {
          setTab2Selected({
            ...tab2Selected,
            currentTab: tab2Selected.currentTab + 1,
          })
        } else {
          setTab2Selected({
            ...tab2Selected,
            currentTab: 1,
          })
        }
      }
      if (wrapperRef3.current && wrapperRef3.current.contains(e.target)) {
        if (
          tab3Selected.currentTab >= 1 &&
          tab3Selected.currentTab < tab3Selected.noTabs
        ) {
          setTab3Selected({
            ...tab3Selected,
            currentTab: tab3Selected.currentTab + 1,
          })
        } else {
          setTab3Selected({
            ...tab3Selected,
            currentTab: 1,
          })
        }
      }
    }

    if (e.keyCode === 37) {
      if (wrapperRef1.current && wrapperRef1.current.contains(e.target)) {
        if (
          tab1Selected.currentTab > 1 &&
          tab1Selected.currentTab <= tab1Selected.noTabs
        ) {
          setTab1Selected({
            ...tab1Selected,
            currentTab: tab1Selected.currentTab - 1,
          })
        } else {
          setTab1Selected({
            ...tab1Selected,
            currentTab: tab1Selected.noTabs,
          })
        }
      }
      if (wrapperRef2.current && wrapperRef2.current.contains(e.target)) {
        if (
          tab2Selected.currentTab > 1 &&
          tab2Selected.currentTab <= tab2Selected.noTabs
        ) {
          setTab2Selected({
            ...tab2Selected,
            currentTab: tab2Selected.currentTab - 1,
          })
        } else {
          setTab2Selected({
            ...tab2Selected,
            currentTab: tab2Selected.noTabs,
          })
        }
      }
      if (wrapperRef3.current && wrapperRef3.current.contains(e.target)) {
        if (
          tab3Selected.currentTab > 1 &&
          tab3Selected.currentTab <= tab3Selected.noTabs
        ) {
          setTab3Selected({
            ...tab3Selected,
            currentTab: tab3Selected.currentTab - 1,
          })
        } else {
          setTab3Selected({
            ...tab3Selected,
            currentTab: tab3Selected.noTabs,
          })
        }
      }
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown)
    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  })

  return (
    <div className="flex flex-col items-center gap-10">
      {/*<!-- Component: Basic lg sized tab with leading icon --> */}
      <CopyComponent
        copyToClipboardCode={TabsLgBasicLeadingIcon}
        componentName="TabsLgBasicLeadingIcon"
      >
        <section className="max-w-full" aria-multiselectable="false">
          <ul
            className="flex items-center overflow-x-auto overflow-y-hidden border-b border-slate-200"
            role="tablist"
            ref={wrapperRef1}
          >
            <li className="" role="presentation">
              <button
                className={`-mb-px inline-flex h-12 w-full items-center justify-center gap-2 whitespace-nowrap rounded-t border-b-2 px-6 text-sm font-medium tracking-wide transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-600 focus:bg-emerald-50 focus-visible:outline-none disabled:cursor-not-allowed ${
                  tab1Selected.currentTab === 1
                    ? `border-emerald-500 stroke-emerald-500 text-emerald-500 hover:border-emerald-600  hover:text-emerald-600 focus:border-emerald-700 focus:stroke-emerald-700 focus:text-emerald-700 disabled:border-slate-500`
                    : `justify-self-center border-transparent stroke-slate-700 text-slate-700 hover:border-emerald-500 hover:text-emerald-500 focus:border-emerald-600 focus:stroke-emerald-600 focus:text-emerald-600 disabled:text-slate-500`
                }`}
                id="tab-label-1abl"
                role="tab"
                aria-setsize="3"
                aria-posinset="1"
                tabindex={`${tab1Selected.currentTab === 1 ? "0" : "-1"}`}
                aria-controls="tab-panel-1abl"
                aria-selected={`${
                  tab1Selected.currentTab === 1 ? "true" : "false"
                }`}
                onClick={() =>
                  setTab1Selected({ ...tab1Selected, currentTab: 1 })
                }
              >
                <span className="order-2 ">Tab 1</span>
                <span className="relative only:-mx-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="hidden w-6 h-6 sm:block"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    aria-labelledby="title-77a desc-77a"
                  >
                    <title id="title-77a">Icon title</title>
                    <desc id="desc-77a">
                      A more detailed description of the icon
                    </desc>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                    />
                  </svg>
                </span>
              </button>
            </li>
            <li className="" role="presentation">
              <button
                className={`-mb-px inline-flex h-12 w-full items-center justify-center gap-2 whitespace-nowrap rounded-t border-b-2 px-6 text-sm font-medium tracking-wide transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-600 focus:bg-emerald-50 focus-visible:outline-none disabled:cursor-not-allowed ${
                  tab1Selected.currentTab === 2
                    ? `border-emerald-500 stroke-emerald-500 text-emerald-500 hover:border-emerald-600  hover:text-emerald-600 focus:border-emerald-700 focus:stroke-emerald-700 focus:text-emerald-700 disabled:border-slate-500`
                    : `justify-self-center border-transparent stroke-slate-700 text-slate-700 hover:border-emerald-500 hover:text-emerald-500 focus:border-emerald-600 focus:stroke-emerald-600 focus:text-emerald-600 disabled:text-slate-500`
                }`}
                id="tab-label-2bl"
                role="tab"
                aria-setsize="3"
                aria-posinset="2"
                tabindex={`${tab1Selected.currentTab === 2 ? "0" : "-1"}`}
                aria-controls="tab-panel-2bl"
                aria-selected={`${
                  tab1Selected.currentTab === 2 ? "true" : "false"
                }`}
                onClick={() =>
                  setTab1Selected({ ...tab1Selected, currentTab: 2 })
                }
              >
                <span className="order-2 ">Tab 2</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="hidden w-6 h-6 sm:block"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  aria-labelledby="title-77b desc-77b"
                >
                  <title id="title-77b">Icon title</title>
                  <desc id="desc-77b">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"
                  />
                </svg>
              </button>
            </li>
            <li className="" role="presentation">
              <button
                className={`-mb-px inline-flex h-12 w-full items-center justify-center gap-2 whitespace-nowrap rounded-t border-b-2 px-6 text-sm font-medium tracking-wide transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-600 focus:bg-emerald-50 focus-visible:outline-none disabled:cursor-not-allowed ${
                  tab1Selected.currentTab === 3
                    ? `border-emerald-500 stroke-emerald-500 text-emerald-500 hover:border-emerald-600  hover:text-emerald-600 focus:border-emerald-700 focus:stroke-emerald-700 focus:text-emerald-700 disabled:border-slate-500`
                    : `justify-self-center border-transparent stroke-slate-700 text-slate-700 hover:border-emerald-500 hover:text-emerald-500 focus:border-emerald-600 focus:stroke-emerald-600 focus:text-emerald-600 disabled:text-slate-500`
                }`}
                id="tab-label-3bl"
                role="tab"
                aria-setsize="3"
                aria-posinset="3"
                tabindex={`${tab1Selected.currentTab === 3 ? "0" : "-1"}`}
                aria-controls="tab-panel-3bl"
                aria-selected={`${
                  tab1Selected.currentTab === 3 ? "true" : "false"
                }`}
                onClick={() =>
                  setTab1Selected({ ...tab1Selected, currentTab: 3 })
                }
              >
                <span className="order-2 ">Tab 3</span>
                <span className="relative only:-mx-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="hidden w-6 h-6 sm:block"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    aria-labelledby="title-77c desc-77c"
                  >
                    <title id="title-77c">Icon title</title>
                    <desc id="desc-77c">
                      A more detailed description of the icon
                    </desc>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"
                    />
                  </svg>
                </span>
              </button>
            </li>
          </ul>
          <div className="">
            <div
              className={`px-6 py-4 ${
                tab1Selected.currentTab === 1 ? "" : "hidden"
              }`}
              id="tab-panel-1abl"
              aria-selected={`${
                tab1Selected.currentTab === 1 ? "true" : "false"
              }`}
              role="tabpanel"
              aria-labelledby="tab-label-1abl"
              tabindex="-1"
            >
              <p>
                What is the recipe for successful achievement? To my mind there
                are just four essential ingredients: Choose a career you love,
                give it the best there is in you, seize your opportunities, and
                be a member of the team.
              </p>
            </div>
            <div
              className={`px-6 py-4 ${
                tab1Selected.currentTab === 2 ? "" : "hidden"
              }`}
              id="tab-panel-2bl"
              aria-selected={`${
                tab1Selected.currentTab === 2 ? "true" : "false"
              }`}
              role="tabpanel"
              aria-labelledby="tab-label-2bl"
              tabindex="-1"
            >
              <p>
                One must be entirely sensitive to the structure of the material
                that one is handling. One must yield to it in tiny details of
                execution, perhaps the handling of the surface or grain, and one
                must master it as a whole.
              </p>
            </div>
            <div
              className={`px-6 py-4 ${
                tab1Selected.currentTab === 3 ? "" : "hidden"
              }`}
              id="tab-panel-3bl"
              aria-selected={`${
                tab1Selected.currentTab === 3 ? "true" : "false"
              }`}
              role="tabpanel"
              aria-labelledby="tab-label-3bl"
              tabindex="-1"
            >
              <p>
                Even though there is no certainty that the expected results of
                our work will manifest, we have to remain committed to our work
                and duties; because, even if the results are slated to arrive,
                they cannot do so without the performance of work.
              </p>
            </div>
          </div>
        </section>
      </CopyComponent>
      {/*<!-- End Basic lg sized tab with leading icon --> */}

      {/*<!-- Component: Basic base sized tab with leading icon --> */}
      <CopyComponent
        copyToClipboardCode={TabsBaseBasicLeadingIcon}
        componentName="TabsBaseBasicLeadingIcon"
      >
        <section className="max-w-full" aria-multiselectable="false">
          <ul
            className="flex items-center overflow-x-auto overflow-y-hidden border-b border-slate-200"
            role="tablist"
            ref={wrapperRef2}
          >
            <li className="" role="presentation">
              <button
                className={`-mb-px inline-flex h-10 w-full items-center justify-center gap-2 whitespace-nowrap rounded-t border-b-2 px-5 text-sm font-medium tracking-wide transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-600 focus:bg-emerald-50 focus-visible:outline-none disabled:cursor-not-allowed ${
                  tab2Selected.currentTab === 1
                    ? `border-emerald-500 stroke-emerald-500 text-emerald-500 hover:border-emerald-600  hover:text-emerald-600 focus:border-emerald-700 focus:stroke-emerald-700 focus:text-emerald-700 disabled:border-slate-500`
                    : `justify-self-center border-transparent stroke-slate-700 text-slate-700 hover:border-emerald-500 hover:text-emerald-500 focus:border-emerald-600 focus:stroke-emerald-600 focus:text-emerald-600 disabled:text-slate-500`
                }`}
                role="tab"
                aria-setsize="3"
                aria-posinset="1"
                tabindex={`${tab2Selected.currentTab === 1 ? "0" : "-1"}`}
                aria-controls="tab-panel-1bi"
                aria-selected={`${
                  tab2Selected.currentTab === 1 ? "true" : "false"
                }`}
                onClick={() =>
                  setTab2Selected({ ...tab2Selected, currentTab: 1 })
                }
              >
                <span className="order-2">Tab 1</span>
                <span className="relative only:-mx-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="hidden w-6 h-6 sm:block"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    aria-labelledby="title-77ab desc-77ab"
                  >
                    <title id="title-77ab">Icon title</title>
                    <desc id="desc-77ab">
                      A more detailed description of the icon
                    </desc>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                    />
                  </svg>
                </span>
              </button>
            </li>
            <li className="" role="presentation">
              <button
                className={`-mb-px inline-flex h-10 w-full items-center justify-center gap-2 whitespace-nowrap rounded-t border-b-2 px-5 text-sm font-medium tracking-wide transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-600 focus:bg-emerald-50 focus-visible:outline-none disabled:cursor-not-allowed ${
                  tab2Selected.currentTab === 2
                    ? `border-emerald-500 stroke-emerald-500 text-emerald-500 hover:border-emerald-600  hover:text-emerald-600 focus:border-emerald-700 focus:stroke-emerald-700 focus:text-emerald-700 disabled:border-slate-500`
                    : `justify-self-center border-transparent stroke-slate-700 text-slate-700 hover:border-emerald-500 hover:text-emerald-500 focus:border-emerald-600 focus:stroke-emerald-600 focus:text-emerald-600 disabled:text-slate-500`
                }`}
                id="tab-label-2bi"
                role="tab"
                aria-setsize="3"
                aria-posinset="2"
                tabindex={`${tab2Selected.currentTab === 2 ? "0" : "-1"}`}
                aria-controls="tab-panel-2bi"
                aria-selected={`${
                  tab2Selected.currentTab === 2 ? "true" : "false"
                }`}
                onClick={() =>
                  setTab2Selected({ ...tab2Selected, currentTab: 2 })
                }
              >
                <span className="order-2">Tab 2</span>
                <span className="relative only:-mx-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="hidden w-6 h-6 sm:block"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    aria-labelledby="title-77bb desc-77bb"
                  >
                    <title id="title-77bb">Icon title</title>
                    <desc id="desc-77bb">
                      A more detailed description of the icon
                    </desc>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"
                    />
                  </svg>
                </span>
              </button>
            </li>
            <li className="" role="presentation">
              <button
                className={`-mb-px inline-flex h-10 w-full items-center justify-center gap-2 whitespace-nowrap rounded-t border-b-2 px-5 text-sm font-medium tracking-wide transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-600 focus:bg-emerald-50 focus-visible:outline-none disabled:cursor-not-allowed ${
                  tab2Selected.currentTab === 3
                    ? `border-emerald-500 stroke-emerald-500 text-emerald-500 hover:border-emerald-600  hover:text-emerald-600 focus:border-emerald-700 focus:stroke-emerald-700 focus:text-emerald-700 disabled:border-slate-500`
                    : `justify-self-center border-transparent stroke-slate-700 text-slate-700 hover:border-emerald-500 hover:text-emerald-500 focus:border-emerald-600 focus:stroke-emerald-600 focus:text-emerald-600 disabled:text-slate-500`
                }`}
                id="tab-label-3bi"
                role="tab"
                aria-setsize="3"
                aria-posinset="3"
                tabindex={`${tab2Selected.currentTab === 3 ? "0" : "-1"}`}
                aria-controls="tab-panel-3bi"
                aria-selected={`${
                  tab2Selected.currentTab === 3 ? "true" : "false"
                }`}
                onClick={() =>
                  setTab2Selected({ ...tab2Selected, currentTab: 3 })
                }
              >
                <span className="order-2">Tab 3</span>
                <span className="relative only:-mx-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="hidden w-6 h-6 sm:block"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    aria-labelledby="title-77cb desc-77cb"
                  >
                    <title id="title-77cb">Icon title</title>
                    <desc id="desc-77cb">
                      A more detailed description of the icon
                    </desc>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"
                    />
                  </svg>
                </span>
              </button>
            </li>
          </ul>
          <div className="">
            <div
              className={`px-5 py-4 ${
                tab2Selected.currentTab === 1 ? "" : "hidden"
              }`}
              id="tab-panel-1bi"
              aria-hidden={`${
                tab2Selected.currentTab === 1 ? "true" : "false"
              }`}
              role="tabpanel"
              aria-labelledby="tab-label-1bi"
              tabindex="-1"
            >
              <p>
                What is the recipe for successful achievement? To my mind there
                are just four essential ingredients: Choose a career you love,
                give it the best there is in you, seize your opportunities, and
                be a member of the team.
              </p>
            </div>
            <div
              className={`px-5 py-4 ${
                tab2Selected.currentTab === 2 ? "" : "hidden"
              }`}
              id="tab-panel-2bi"
              aria-hidden={`${
                tab2Selected.currentTab === 2 ? "true" : "false"
              }`}
              role="tabpanel"
              aria-labelledby="tab-label-2bi"
              tabindex="-1"
            >
              <p>
                One must be entirely sensitive to the structure of the material
                that one is handling. One must yield to it in tiny details of
                execution, perhaps the handling of the surface or grain, and one
                must master it as a whole.
              </p>
            </div>
            <div
              className={`px-5 py-4 ${
                tab2Selected.currentTab === 3 ? "" : "hidden"
              }`}
              id="tab-panel-3bi"
              aria-hidden={`${
                tab2Selected.currentTab === 3 ? "true" : "false"
              }`}
              role="tabpanel"
              aria-labelledby="tab-label-3bi"
              tabindex="-1"
            >
              <p>
                Even though there is no certainty that the expected results of
                our work will manifest, we have to remain committed to our work
                and duties; because, even if the results are slated to arrive,
                they cannot do so without the performance of work.
              </p>
            </div>
          </div>
        </section>
      </CopyComponent>
      {/*<!-- End Basic base sized tab with leading icon --> */}

      {/*<!-- Component: Basic sm sized tab with leading icon --> */}
      <CopyComponent
        copyToClipboardCode={TabsSmBasicLeadingIcon}
        componentName="TabsSmBasicLeadingIcon"
      >
        <section className="max-w-full" aria-multiselectable="false">
          <ul
            className="flex items-center overflow-x-auto overflow-y-hidden border-b border-slate-200"
            role="tablist"
            ref={wrapperRef3}
          >
            <li className="" role="presentation">
              <button
                className={`-mb-px inline-flex h-8 w-full items-center justify-center gap-2 whitespace-nowrap rounded-t border-b-2 px-4 text-xs font-medium tracking-wide transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-600 focus:bg-emerald-50 focus-visible:outline-none disabled:cursor-not-allowed ${
                  tab3Selected.currentTab === 1
                    ? `border-emerald-500 stroke-emerald-500 text-emerald-500 hover:border-emerald-600  hover:text-emerald-600 focus:border-emerald-700 focus:stroke-emerald-700 focus:text-emerald-700 disabled:border-slate-500`
                    : `justify-self-center border-transparent stroke-slate-700 text-slate-700 hover:border-emerald-500 hover:text-emerald-500 focus:border-emerald-600 focus:stroke-emerald-600 focus:text-emerald-600 disabled:text-slate-500`
                }`}
                id="tab-label-1cbl"
                role="tab"
                aria-setsize="3"
                aria-posinset="1"
                tabindex={`${tab3Selected.currentTab === 1 ? "0" : "-1"}`}
                aria-controls="tab-panel-1cbl"
                aria-selected={`${
                  tab3Selected.currentTab === 1 ? "true" : "false"
                }`}
                onClick={() =>
                  setTab3Selected({ ...tab3Selected, currentTab: 1 })
                }
              >
                <span className="order-2">Tab 1</span>
                <span className="relative only:-mx-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    aria-labelledby="title-77as desc-77as"
                  >
                    <title id="title-77as">Icon title</title>
                    <desc id="desc-77as">
                      A more detailed description of the icon
                    </desc>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                    />
                  </svg>
                </span>
              </button>
            </li>
            <li className="" role="presentation">
              <button
                className={`-mb-px inline-flex h-8 w-full items-center justify-center gap-2 whitespace-nowrap rounded-t border-b-2 px-4 text-xs font-medium tracking-wide transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-600 focus:bg-emerald-50 focus-visible:outline-none disabled:cursor-not-allowed ${
                  tab3Selected.currentTab === 2
                    ? `border-emerald-500 stroke-emerald-500 text-emerald-500 hover:border-emerald-600  hover:text-emerald-600 focus:border-emerald-700 focus:stroke-emerald-700 focus:text-emerald-700 disabled:border-slate-500`
                    : `justify-self-center border-transparent stroke-slate-700 text-slate-700 hover:border-emerald-500 hover:text-emerald-500 focus:border-emerald-600 focus:stroke-emerald-600 focus:text-emerald-600 disabled:text-slate-500`
                }`}
                id="tab-label-2ci"
                role="tab"
                aria-setsize="3"
                aria-posinset="2"
                tabindex={`${tab3Selected.currentTab === 2 ? "0" : "-1"}`}
                aria-controls="tab-panel-2ci"
                aria-selected={`${
                  tab3Selected.currentTab === 2 ? "true" : "false"
                }`}
                onClick={() =>
                  setTab3Selected({ ...tab3Selected, currentTab: 2 })
                }
              >
                <span className="order-2">Tab 2</span>
                <span className="relative only:-mx-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    aria-labelledby="title-77bs desc-77bs"
                  >
                    <title id="title-77bs">Icon title</title>
                    <desc id="desc-77bs">
                      A more detailed description of the icon
                    </desc>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"
                    />
                  </svg>
                </span>
              </button>
            </li>
            <li className="" role="presentation">
              <button
                className={`-mb-px inline-flex h-8 w-full items-center justify-center gap-2 whitespace-nowrap rounded-t border-b-2 px-4 text-xs font-medium tracking-wide transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-600 focus:bg-emerald-50 focus-visible:outline-none disabled:cursor-not-allowed ${
                  tab3Selected.currentTab === 3
                    ? `border-emerald-500 stroke-emerald-500 text-emerald-500 hover:border-emerald-600  hover:text-emerald-600 focus:border-emerald-700 focus:stroke-emerald-700 focus:text-emerald-700 disabled:border-slate-500`
                    : `justify-self-center border-transparent stroke-slate-700 text-slate-700 hover:border-emerald-500 hover:text-emerald-500 focus:border-emerald-600 focus:stroke-emerald-600 focus:text-emerald-600 disabled:text-slate-500`
                }`}
                id="tab-label-3ci"
                role="tab"
                aria-setsize="3"
                aria-posinset="3"
                tabindex={`${tab3Selected.currentTab === 3 ? "0" : "-1"}`}
                aria-controls="tab-panel-3ci"
                aria-selected={`${
                  tab3Selected.currentTab === 3 ? "true" : "false"
                }`}
                onClick={() =>
                  setTab3Selected({ ...tab3Selected, currentTab: 3 })
                }
              >
                <span className="order-2">Tab 3</span>
                <span className="relative only:-mx-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    aria-labelledby="title-77cs desc-77cs"
                  >
                    <title id="title-77cs">Icon title</title>
                    <desc id="desc-77cs">
                      A more detailed description of the icon
                    </desc>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"
                    />
                  </svg>
                </span>
              </button>
            </li>
          </ul>
          <div className="">
            <div
              className={`p-4 text-sm ${
                tab3Selected.currentTab === 1 ? "" : "hidden"
              }`}
              id="tab-panel-1cbl"
              aria-hidden={`${
                tab3Selected.currentTab === 1 ? "true" : "false"
              }`}
              role="tabpanel"
              aria-labelledby="tab-label-1cbl"
              tabindex="-1"
            >
              <p>
                What is the recipe for successful achievement? To my mind there
                are just four essential ingredients: Choose a career you love,
                give it the best there is in you, seize your opportunities, and
                be a member of the team.
              </p>
            </div>
            <div
              className={`p-4 text-sm ${
                tab3Selected.currentTab === 2 ? "" : "hidden"
              }`}
              id="tab-panel-2ci"
              aria-hidden={`${
                tab3Selected.currentTab === 2 ? "true" : "false"
              }`}
              role="tabpanel"
              aria-labelledby="tab-label-2ci"
              tabindex="-1"
            >
              <p>
                One must be entirely sensitive to the structure of the material
                that one is handling. One must yield to it in tiny details of
                execution, perhaps the handling of the surface or grain, and one
                must master it as a whole.
              </p>
            </div>
            <div
              className={`p-4 text-sm ${
                tab3Selected.currentTab === 3 ? "" : "hidden"
              }`}
              id="tab-panel-3ci"
              aria-hidden={`${
                tab3Selected.currentTab === 3 ? "true" : "false"
              }`}
              role="tabpanel"
              aria-labelledby="tab-label-3ci"
              tabindex="-1"
            >
              <p>
                Even though there is no certainty that the expected results of
                our work will manifest, we have to remain committed to our work
                and duties; because, even if the results are slated to arrive,
                they cannot do so without the performance of work.
              </p>
            </div>
          </div>
        </section>
      </CopyComponent>
      {/*<!-- End Basic sm sized tab with leading icon --> */}
    </div>
  )
}
